.Toastify__toast--dark {
    background: #32325d !important;
    color: #fff;
}

.Toastify__toast--default {
    background: #f6f9fc !important;
    color: #aaa;
}

.Toastify__toast--info {
    background: #2962ff !important;
}

.Toastify__toast--success {
    background: #2dce89 !important;
}

.Toastify__toast--warning {
    background: #fb6340 !important;
}

.Toastify__toast--error {
    background: #f62d51 !important;
}

.border-bottom-w-5 {
    border-bottom: solid #aaa !important;
    border-width: thick;
}

.border-right-only {
    border-left: none;
    border-top: none;
    border-right: solid #3e5569 !important;
    border-bottom: none;
}

.pointer {
    cursor: pointer;
}

ol.breadcrumb {
    margin: 0;
}

.vcard {
    background-color: #3c424e;
    width: 100%;
    padding-top: 63%; /* 1:1 Aspect Ratio */
    position: relative; /* If you want text inside of it */
    border-radius: 5px;
    border: 1px solid #e9ecef;
}

/* If you want text inside of the container */
.vcard-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}


