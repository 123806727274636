.files input {
    border: none;
    outline: 1px dashed #92b0b3;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 120px 0px 85px 35%;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
}

.files input:focus {
    outline: 1px dashed #92b0b3;
    outline-offset: -5px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
}

.files {
    position: relative
}

.files:after {
    pointer-events: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 50px;
    right: 0;
    height: 56px;
    content: "";
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
}

.color input {
    background-color: #fff;
}

.files:before {
    position: absolute;
    top: 50px;
    left: 0;
    pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    content: "Drag & Drop";
    display: block;
    margin: 0 auto;
    color: #3e5569;
    font-size: x-large;
    font-weight: bolder;
    text-transform: capitalize;
    text-align: center;
}

input[type=file]::file-selector-button {
    /*padding: .2em .4em;*/
    /*border-radius: 20px;*/
    /*background-color: #32325d;*/
    /*color: #fff;*/

    position: relative;
    font-weight: bolder;
    display: block;
    padding: 5px 20px;

    overflow: hidden;

    border-width: 0;
    outline: none;
    border-radius: 20px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .6);

    background-color: #32325d;
    color: #fff;

    transition: background-color .3s;
}

input[type=file]::file-selector-button:hover {
    background-color: #81ecec;
    border: 2px solid #00cec9;
}

#dndlabel {
    position: relative;
    top: -50px;
}
