.modal-bottom {
  display: block !important;
  .modal-dialog{
    // height:80%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100% !important;
    margin: 0;
    overflow: initial !important;
    .modal-content{
      border-radius: 3rem 3rem 0 0;
      overflow: auto;
      height: 90vh;
    }
  }
}
.modal-p-bottom {
  // &.show{
  //   overflow: hidden;
  // }
  display: block !important;
  .modal-dialog{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100% !important;
    margin: 0;
    transform:translateY(100%) !important;
    overflow: initial !important;
    .modal-content{
      border-radius: 2rem 2rem 0 0;
      overflow: auto;
      height: 90vh;
    }
  }

  &.show{
    overflow: hidden;
    .modal-dialog{
      transform:translateY(0%) !important;
      min-height:80px;
    }

  }
}

.vl {
  border-left: 6px solid grey;
  height: 500px;
  float: right;
}

.change-summary {
  height: 500px;
  border: dashed;
}
